/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,700;0,800;1,300;1,400;1,700;1,800&family=Roboto:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

// 'Lato' 300 400 700 900
// 'Open Sans' 300 400 500 700 800
// 'Roboto' 300 400 700 900

/*  
    Puede que eventualmente las tipografia cambie por la siguiente
    
    Jost
    https://fonts.google.com/specimen/Jost?query=jost

    Cormorant
    https://fonts.google.com/specimen/Cormorant

    Open Sans
    https://fonts.google.com/specimen/Open+Sans?query=open+sans
*/

.espaciar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

ion-title,
ion-card-title,
ion-note {
    font-family: "Lato";
    font-weight: 900;
}
ion-label,
ion-button,
ion-card-subtitle,
ion-select-option {
    font-family: "Roboto";
}

ion-button {
    font-size: 1em;
    text-transform: none;
}

ion-text {
    font-family: "Open Sans";
    font-size: 0.9em;
}
